<template>
  <div class="main-div">
    <div class="image-div">

    </div>
    <div class="login-div">
      <div class="text-center login-card">
        <img src="https://parabellum.si/wp-content/uploads/2023/02/logoW.png" class="logo"  alt="Parabellum"/>
        <h3 style="color: white">Registracija</h3>
        <div class="info-div text-left">
          <p style="color: white; margin-bottom: 0">Ime člana: <b>{{initData.name}}</b></p>
          <p style="color: white; margin-bottom: 0">Rojen: <b>{{initData.birthday}}</b></p>
          <p style="color: white; margin-bottom: 0">Orožje ali listina: <b>{{initData.hasFirearmPermitOrFirearm ? "DA" : "NE"}}</b></p>
        </div>
        <v-text-field
            style="margin-top: 20px"
            outlined
            label="Uporabniško ime"
            v-model="userCredentials.username"
            dark
            disabled
        >
        </v-text-field>
        <v-text-field
            outlined
            label="Geslo"
            v-model="userCredentials.password"
            dark
            type="password"
        >
        </v-text-field>
        <v-text-field
            outlined
            label="Ponovi geslo"
            v-model="userCredentials.password_repeat"
            dark
            type="password"
        >
        </v-text-field>
        <div v-show="message !== ''">
          <v-icon color="yellow" large>mdi-alert</v-icon>
          <p style="color: #fcfc00; margin-top: 10px; margin-bottom: 30px">{{message}}</p>
        </div>
        <v-btn
            color="primary"
            style="width: 100%"
            @click="handleSignup()"
            :loading="loading"
            dark
        >
          Registracija
        </v-btn>

        <v-expand-transition>
          <v-snackbar
              v-model="snackbar"
              :timeout="4000"
              :color="snackbarColor"
              :elevation="24"
              multi-line
              left
              top
              absolute
          >
            {{ message }}
          </v-snackbar>
        </v-expand-transition>
      </div>
    </div>




  </div>
</template>

<script>


import ApiConnector from "@/services/api-connector.service";
import ApiRequest from "@/models/api-request";
import {REQUEST_METHODS} from "@/models/request-methods";

export default {
  name: "signup",
  title: "Registracija",
  data() {
    return {
      userCredentials: {},
      loading: false,
      memberId: true,
      initData: null,

      message: "",
      snackbar: false,
      snackbarColor: "#a82121",
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    console.log("route parameter for memberId: " + this.$route.params.id);
    this.userCredentials.username = "";
    this.userCredentials.password = "";
    this.userCredentials.password_repeat = "";
    this.memberId = this.$route.params.id;

    if (this.currentUser !== null) {
      console.log("User logged in... Pushing to /memeber")
      this.$router.push("/member");
    }

    ApiConnector.executeCall(ApiRequest.BuildWithURL("sign-up/" + this.memberId)).then(
        response => {
          this.initData = response.data;
          this.userCredentials.username = this.initData.email;
        },
        error => {
          this.$router.push('/login');
          console.log("Could not get init data from server.")
          console.log(error.response.data.message)
        }
    )

  },
  methods: {
    handleSignup() {
      this.loading = true;
      if (this.userCredentials.username !== "" && this.userCredentials.password !== "" && this.userCredentials.password_repeat !== ""){
        if(this.userCredentials.password === this.userCredentials.password_repeat){
          if(this.userCredentials.password.length >= 8){
            ApiConnector.executeCall(ApiRequest.BuildWithURLAndMethodAndBody(
                "sign-up/" + this.memberId,
                REQUEST_METHODS.POST,
                this.userCredentials
            )).then(
                response => {
                  localStorage.setItem('user', JSON.stringify(response.data));
                  this.$store.dispatch('auth/authenticateSignup', response.data)
                  console.log("Pushing to /memeber")
                  this.$router.push('/member');
                },
                error => {
                  this.message = "Registracija je bila neuspešna!"
                  this.snackbar = true
                  this.loading = false
                  console.log("Could not execute signup.")
                  console.log(error.response.data.message)
                }
            );
          }
          else {
            this.message = "Geslo je prekratko!"
            this.snackbar = true
            this.loading = false
          }

        }
        else {
          this.message = "Gesli se ne ujemata!"
          this.snackbar = true
          this.loading = false
        }
      }
      else {
        this.message = "Uporabniško ime in geslo sta obvezna!"
        this.snackbar = true
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

  .main-div {
    display: flex;
  }
  .image-div {
    flex: 2;
    background-color: black;
    height: 100vh;
    background-image: url("https://parabellum.si/wp-content/uploads/2023/02/sun-bullets-target.jpg");
  }
  .login-div {
    flex: 1;
    min-width: 500px;
    height: 100vh;
    background-image: linear-gradient(45deg, #7d7d7d, black);
    overflow: scroll;
  }
  .login-card {
    width: 450px;
    margin: auto;
    padding: 80px;
    margin-top: 5%;
    margin-bottom: 5%;
    border: solid 1px gainsboro;
    border-radius: 30px;
    background-color: #424242;
  }
  .logo {
    width: 120px;
    margin-bottom: 30px;
  }

  .info-div {
    border: solid 1px gray;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
  }


  @media only screen and (max-width: 1000px){
    .image-div{
      display: none;
    }
    .login-card {
      width: 450px;
      margin: auto;
      padding: 80px;
      margin-top: 20%;
      position: sticky;
      top: 0;
    }
    .login-div {
      width: 100%;
      min-width: unset;
      background-image: unset
    }
  }
  @media only screen and (max-width: 500px){
    .main-div {
      display: unset;
    }
    .login-card {
      width: 90%;
      justify-self: center;
      padding: 15px;
      padding-top: 80px;
      padding-bottom: 80px;
      margin-top: 5%
    }


  }
</style>
